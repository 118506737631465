import { Injectable } from '@angular/core';
import { BaseService } from '@app-core/base-service';
import { REST_URI, REST_URI_MODULE } from '@app-core/constants';
import { Login } from '@app-models/login';
import { ValidationError } from '@app-models/validation-error';
import { RestService } from './http-rest.service';

@Injectable()
export class LoginService extends BaseService {

  constructor(private restService: RestService) {
    super();
  }

  authenticateLogin(loginData: Login, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    this.restService.post(REST_URI_MODULE.USERS + '/' + REST_URI.LOGIN, loginData).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  logout(successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    this.restService.post(REST_URI_MODULE.USERS + '/' + REST_URI.LOGOUT, {}).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  forgotPassword(email: string, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    this.restService.post(REST_URI_MODULE.USERS + '/' + REST_URI.FORGOTPASSWORD, { 'email': email }).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  acceptInvitation(data: any, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    this.restService.get(REST_URI_MODULE.USERS + '/' + REST_URI.ACCEPTINVITATION + `${data.userId}/${data.orgId}`).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  getAccessToken() {
    return '';

  }

  refreshAccessToken() {
    return '';
  }
}
