import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS, NAVIGATE_URI } from '@app-core/constants';
import { ValidationError } from '@app-models/validation-error';
import { LoginService } from '@app-services/login.service';
import { SnotifyService } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent implements OnInit {

  userId
  orgId
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _loginService: LoginService,
    private _snotifyService: SnotifyService,
    private _spinner: NgxSpinnerService,
    private _router: Router,
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.userId = params['userId'];
      this.orgId = params['orgId'];
    });
  }

  ngOnInit() {
    this._spinner.show();
    setTimeout(() => {
      this.verifyInvitation()
    }, 2000);
  }

  verifyInvitation() {
    this._loginService.acceptInvitation({
      userId: this.userId,
      orgId: this.orgId
    },
      (res: any) => {
        if (res.statusCode === '200') {
          this._router.navigate([NAVIGATE_URI.LOGIN]);
          this._snotifyService.success(res.statusMessage, 'Invitation', CONSTANTS.SnotifyToastNotificationConfig);
          this._spinner.hide();
        }
      },
      (error: ValidationError) => {
        // error case...
        // this.loader = false;
        this._spinner.hide();
        const err = error.obj.apiReqErrors.errors[0];
        this._snotifyService.error(err.errorMessage, CONSTANTS.ERR_TITLE, CONSTANTS.SnotifyToastNotificationConfig);
      })
  }


}
