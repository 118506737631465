<div class="logoutDialog">
  <div class="greeting">
    <h2 class="pb-3" mat-dialog-title>Log Out</h2>
    <h1 mat-dialog-title>{{data.name}}</h1>
  </div>


  <div class="btn-padding pt-4" style="text-align: end;">
    <button type="button" class="btn  btn-danger mr-20" (click)="onNoClick('no')">No</button>
    <button type="button" class="btn Sub-Btn" (click)="onNoClick('yes')" cdkFocusInitial>Yes</button>
  </div>
</div>