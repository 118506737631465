import { REST_URI } from './constants';
import { HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@app-core/constants';
import { SecurityObject } from '@app-models/security-object';
import { StorageService } from '@app-services/storage.service';
import { VERSION } from '../../assets/js/version.js';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpsInterceptorService {

  securityObject: SecurityObject;
  SKIP_URLS: string[];

  constructor(private _storageService: StorageService) {
    this.SKIP_URLS = [REST_URI.LOGIN,REST_URI.ACCEPTINVITATION];
  }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const data: SecurityObject = this._storageService.getItem(CONSTANTS.SECURITY_OBJ);
    this.securityObject = data ? data : new SecurityObject();
    if (req.url.includes("studyParameter/upload/")) {
      req = req.clone({
        setHeaders: { 'Authorization': ` ${this.securityObject.Authorization}` , 'Version':VERSION.version, 'Type':'admin'},
      });
    }

    if (!this.isURLSkippable(req.url)) {
      //add authorization header with jwt token if available
      req = req.clone({
        setHeaders: { 'Authorization': ` ${this.securityObject.Authorization}`, 'Content-Type': 'application/json' ,'Version':VERSION.version, 'Type':'admin'}
      });
    }
    else{
      req = req.clone({
        setHeaders: {'Version':VERSION.version, 'Type':'admin'}
      });
    }
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if ((err.status === 500 && err.error === "New version is availabe") || (err.status === 500 && err.error.message === "New version is availabe")) {
         window.location.reload();
        }
        return throwError(err);
      }))
  }

  private isURLSkippable(requestURL: string): boolean {
    const finalURL = REST_URI.BASE_URL + requestURL;
    return this.SKIP_URLS.some(e => finalURL.indexOf(e) > -1);
  }
}
