<div class="wrapper">
  <app-sidebar [ngClass]="{'active': toggleBtn}"></app-sidebar>

  <div id="content">
    <app-toolbar (openSidebarOutput)="openSidebarInput($event)"></app-toolbar>

    <div class="ContentArea">
      <router-outlet></router-outlet>
    </div>
  </div>
