export class SecurityObject {

    public Authorization: string;
    public roleId: number;
    public roleName: string;
    public name: string;
    public PRIVILEGE_LIST_USER: any;
    public userId: string;
    public orgId: string;
    public orgList: OrgList[];
    constructor() {}
}


export class OrgList {
    public city: string;
    public addressTwo: string;
    public pinCode: string;
    public name: string;
    public active: true;
    public id: number;
    public state: string;
    public addressOne: string;
    public email: string;
    public phoneNo: string;
    constructor() {}
}