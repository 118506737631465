import { CviSplashScreenService } from './shared/services/splash-screen.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'dicomAdmin';
  constructor(private _cviSplashScreenService: CviSplashScreenService
  ) {
  }

  ngOnInit(): void {
  }

}
