<div class="ContentArea">
  <h1 matDialogTitle>{{data.title}}</h1>
  <div mat-dialog-content>{{data.confirmMessage}}</div>
  <div mat-dialog-actions>
    <div class="col-12 col-sm-12 btn-right pt-3">
      <div class="btn-padding">
        <button type="button" class="btn  btn-danger mr-20" (click)="close('Cancel')">Cancel</button>
        <ng-container *ngIf='data.isDelete'>
        <button type="button" class="btn Sub-Btn" (click)="close('Delete')">Delete</button>
      </ng-container>
      <ng-container *ngIf='!data.isDelete'>
        <button type="button" class="btn Sub-Btn" (click)="close('Confirm')">Confirm</button>
      </ng-container>
      </div>
    </div>
  </div>
</div>
