import { SEARCH_URL } from './../../core/constants';
import { CONSTANTS } from '@app-core/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '@app-services/login.service';
import { NAVIGATE_URI } from '@app-core/constants';
import { Router, NavigationEnd } from '@angular/router';
import { StorageService } from './../../shared/services/storage.service';
import {LogoutDilogModelComponent} from "./../../shared/components/logout-dilog-model/logout-dilog-model.component"
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import { ValidationError } from '@app-models/validation-error';
import { SnotifyService } from 'ng-snotify';
import { SecurityObject } from '@app-models/security-object';
import { SubjectService } from '@app-services/subject.service';
import { reduce } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {
  toggleBtn: boolean = false;
  orgName: string = '';
  securityObject: SecurityObject;
  @Output() openSidebarOutput = new EventEmitter<boolean>();
  isSearchbarShow = false;
  searchBox: any = '';
  currentURL: any = [];
  message: string='Are you sure, You want to Logout ?';
  isYesButtonClicked:boolean=false;
  searchValue: any;
  constructor(
    private _storageService: StorageService,
    private _router: Router,
    private _loginService: LoginService,
    private _spinner: NgxSpinnerService,
    private _snotifyService: SnotifyService,
    private _subjectService: SubjectService,
    private _dialog: MatDialog,
  ) {
    const data: SecurityObject = this._storageService.getItem(CONSTANTS.SECURITY_OBJ);
    this.securityObject = data ? data : new SecurityObject();
    this.orgName = this.securityObject.orgList.length > 0 ? this.securityObject.orgList[0].name : '' 


    this._subjectService.getUpdateProfile().subscribe(res => {
      const data: SecurityObject = this._storageService.getItem(CONSTANTS.SECURITY_OBJ);
      this.securityObject = data ? data : new SecurityObject();
    });

    this._router.events.subscribe(val => {
      // console.log(val)
      if (val instanceof NavigationEnd) {
        this.currentURL = SEARCH_URL.filter(res => res.url.includes(val.url));
        if (this.currentURL.length > 0) {
          this.isSearchbarShow = true;
          this.searchBox = '';
        } else {
          this.isSearchbarShow = false;
        }
      }
    });
  }

  ngOnInit() { }

  openSidebar() {
    this.openSidebarOutput.emit((this.toggleBtn = !this.toggleBtn));
  }

  openDialog(): void {
    const dialogRef = this._dialog.open(LogoutDilogModelComponent, {
      width: '360px',
      data: {name: this.message},
      panelClass: 'logoutDialog'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result==='yes'){
       this.logout();
      }
    });
  }

  logout() {
    this._spinner.show();
    this._loginService.logout(
      (res: any) => {
        if (res.statusCode === '200') {
          setTimeout(() => {
            this._spinner.hide();
            this._storageService.clear();
            this._router.navigateByUrl(NAVIGATE_URI.LOGIN);
          }, 1500);
        }
      },
      (error: ValidationError) => {
        // error case...
        this._spinner.hide();
        this._storageService.clear();
        this._router.navigateByUrl(NAVIGATE_URI.LOGIN);
        const err = error.obj.apiReqErrors.errors[0];
        this._snotifyService.error(
          err.errorMessage,
          CONSTANTS.ERR_TITLE,
          CONSTANTS.SnotifyToastNotificationConfig
        );
      }
    );
  }

  goToProfile() {
    this._router.navigateByUrl(NAVIGATE_URI.USERPROFILE);
  }

  search(event) {
    this.searchValue = event.target.value;
    if(this.currentURL[0].name!=='Studies'){
      this._subjectService.sendData(this.searchValue, this.currentURL[0].name);
    }
    if(this.currentURL[0].name=='Studies' && this.searchValue==""){
      this._subjectService.sendData(this.searchValue, this.currentURL[0].name);
    }
  }
  getSearchIconClick(){
    if(this.currentURL[0].name=='Studies' && this.searchValue){
      this._subjectService.sendData(this.searchValue, this.currentURL[0].name);
    }
    else{
      if(this.currentURL[0].name=='Studies')
        this._snotifyService.error('error',`Enter Valid Text`)
    }
  }
}

export function customFilterPredicate(filterFields): (data: any, filter: string) => boolean {
  const filterFunction = function (data, filter): boolean {
    const filtervalue = filterFields;
    filtervalue.map(res => {
      data[res] = data[res] ? data[res] : '';
    });
   if (filtervalue.map(res => data[res].toString().toLowerCase().indexOf(filter) !== -1 ? true : false).includes(true)){
   return true;
   } else {
   return false;
   }
  };
  return filterFunction;
};