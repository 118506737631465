import { HttpErrorResponse } from '@angular/common/http';
export class UnKnownError {
    private _code = 0;
    private _type = 'error';
    private _message  = '';
    constructor(public error?: HttpErrorResponse) {
    }
    get code(): number {
        return this._code = this.error.status;
    }
    get type(): string {
        return this._type;
    }

    get message(): string {
        return this._message = this.error.statusText;
    }
}
