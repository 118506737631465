import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SecurityObject } from '@app-models/security-object';
import { StorageService } from '@app-services/storage.service';
import { SnotifyService } from 'ng-snotify';
import { CONSTANTS, NAVIGATE_URI, BLOCKED_URL, PRIVILEGE_URL, REDIRECT_URI } from './constants';

@Injectable({
  providedIn: 'root'
})

export class AuthGurdService {

  constructor(private _router: Router,
              private _storageService: StorageService,
              private _snotifyService: SnotifyService) {}
  isLoggedIn(url): boolean {
    try {
      const theUser: SecurityObject = this._storageService.getItem(CONSTANTS.SECURITY_OBJ);
      if (theUser) {
        // const aaa = this.checkAccess(theUser, url);
        // if (aaa) {
        //   return true;
        // } else {
        //   this._router.navigate(['apps/user/dashboard']);
        //   return true;
        // }
        if (url !== NAVIGATE_URI.DASHBOARD) {
          if (this.checkAccess(theUser, url.split('?')[0])) {
              return true;
          } else {
            this._router.navigate(['apps/dashboard']);
          }
        }
       return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  canActivate(_RoutesActive: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    if (this.isLoggedIn(_state.url)) {
      return true;
    }

    this._snotifyService.warning('You are not allowed to access this page. Please login first.', CONSTANTS.SnotifyToastNotificationConfig);
    this._storageService.clear();
    this._router.navigate([NAVIGATE_URI.LOGIN]);
    return false;

  }


  checkAccess(user, url): any {
    let data = [];
    const id = PRIVILEGE_URL.filter(res => res.url.includes(url));
    if (id && id.length > 0) {
      data = user.PRIVILEGE_LIST_USER.filter(prlg => prlg.id === id[0].id);
    }
    if (data.length > 0) {
      return true;
    } else {
      //  this._router.navigate(['/apps/dashboard']);
      return false;
    }
  }

}

