import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-logout-dilog-model',
  templateUrl: './logout-dilog-model.component.html',
  styleUrls: ['./logout-dilog-model.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class LogoutDilogModelComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LogoutDilogModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(buttonValue: any): void {
    this.dialogRef.close(buttonValue);
  }
  ngOnInit() {
  }

}

export interface DialogData {
  animal: string;
  name: string;
}
