<div class="Topbar">
  <div class="row align-items-center">
    <div class="col-1">
      <button (click)="openSidebar()" type="button" id="sidebarCollapse" class="btn">
        <!-- <i class="fas fa-align-left"></i> -->
        <fa-icon [icon]="['fas','align-left']"></fa-icon>
      </button>
    </div>
    <div class="col-4">
      <div *ngIf="isSearchbarShow" class="T-search d-flex align-items-center">
        <input [(ngModel)]="searchBox" (input)="search($event)" placeholder="Search Keyword" (keydown.enter)="getSearchIconClick()">
        <fa-icon [icon]="['fas','search']" class="cursor-point" (click)="getSearchIconClick()"></fa-icon>
      </div>

    </div>
    <div class="col-7 text-right">
      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{securityObject.name}} ({{orgName}})
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="goToProfile()">User Profile</a>
          <a class="dropdown-item" (click)="openDialog()">Logout</a>
        </div>
      </div>
    </div>
  </div>

</div>
