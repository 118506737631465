import { RouterModule } from '@angular/router';
import { Error404ComponentComponent } from './shared/components/error404-component/error404-component.component';
import { GlobalErrorHandlerService } from './core/global-error-handle.service';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppSharedModule } from '@app-core/app-shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpsInterceptorService } from '@app-core/https-interceptor.service';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { LogoutDilogModelComponent } from './shared/components/logout-dilog-model/logout-dilog-model.component';
import { AcceptInvitationComponent } from './shared/components/accept-invitation/accept-invitation.component';
import { ChartsModule, ThemeService } from 'ng2-charts';

@NgModule({
  declarations: [
    AppComponent,
    Error404ComponentComponent,
    LogoutDilogModelComponent,
    AcceptInvitationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    AppSharedModule,
    SnotifyModule,
    ChartsModule
  ],
  entryComponents:[LogoutDilogModelComponent],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptorService, multi: true },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    ThemeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
