import { NumbersOnlyDirective, CharsOnlyDirective } from './../shared/directive/numberolny-directive';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatCardModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatTooltipModule
} from '@angular/material';
import { ConfirmDialogModalComponent } from '@app-components/confirm-dialog-modal/confirm-dialog-modal.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAlignLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ConfirmDialogModalComponent,
    NumbersOnlyDirective,
    CharsOnlyDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMenuModule,
    MatToolbarModule,
    MatRadioModule,
    MatCardModule,
    MatAutocompleteModule,
    MatDialogModule,
    NgxSpinnerModule,
    FlexLayoutModule,
    FontAwesomeModule,
    MatTooltipModule
  ],
  exports: [
    NumbersOnlyDirective,
    CharsOnlyDirective,
    HttpClientModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMenuModule,
    MatToolbarModule,
    MatRadioModule,
    MatCardModule,
    MatAutocompleteModule,
    MatDialogModule,
    NgxSpinnerModule,
    FlexLayoutModule,
    FontAwesomeModule,
    MatTooltipModule
  ],
  providers: [
  ],
  entryComponents: [
    ConfirmDialogModalComponent
  ]
})
export class AppSharedModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faAlignLeft, faSearch);
  }
}
