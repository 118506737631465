import { Router } from '@angular/router';
import { NAVIGATE_URI } from '@app-core/constants';
import { ErrorHandler, Injectable, NgZone, Injector } from '@angular/core';
import { AppError } from '@app-models/app-error';
import { UnauthorizedError } from '@app-models/unauthorized-error';
import { SnotifyService } from 'ng-snotify';
import { CONSTANTS } from './constants';
import { UnKnownError } from '@app-models/unknown-error';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from '@app-services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private _snotifyService: SnotifyService,
    private zone: NgZone,
    private _spinner: NgxSpinnerService,
    private _storageService: StorageService,
    private injector: Injector
  ) { }
  handleError(error: Error | AppError | UnauthorizedError) {
    let message: string;
    // let stackTrace;
    console.log(error);

    if (error instanceof AppError) {
      // Server error
      message = this.getServerErrorMessage(error);
      // stackTrace = errorService.getServerErrorStackTrace(error);
      // notifier.showError(message);
    } else if (error instanceof UnauthorizedError) {
      // Server error
      // message =
     message = this.getServerUnauthorizedErrorMessage(error);
    } else if (error instanceof UnKnownError) {
      message = this.getServerUnknownErrorMessage(error);
    } else {
      // Client Error
      message = this.getClientErrorMessage(error as Error);
      console.log('Client Error:' + message);

      message = 'An error has occured while processing your request.';
      message = '';
      // notifier.showError(message);
    }
    if (message !== '') {
      this.logError(message);
    }
  }
  getClientErrorMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }

  getServerErrorMessage(error: AppError): string {
    let errorServerMessage = 'Something went wrong while processing your request.';
    if (error && error.obj && error.obj.apiReqErrors && error.obj.apiReqErrors.errors) {
      errorServerMessage = error.obj.apiReqErrors.errors[0].errorMessage;
    }
    return navigator.onLine ?  errorServerMessage : 'No Internet Connection';
  }

  getServerUnauthorizedErrorMessage(error: UnauthorizedError) {
    // return error.message ? error.message : error.toString();
    this._storageService.clear();
    this.zone.run(() => {
      const router = this.injector.get(Router);
      router.navigate([NAVIGATE_URI.LOGIN]);
      // tslint:disable-next-line: max-line-length
      this._snotifyService.error(error.message ? error.message : error.toString(), CONSTANTS.ERR_TITLE, CONSTANTS.SnotifyToastNotificationConfig);
      this._spinner.hide();
    });
    return '';
  }

  getServerUnknownErrorMessage(error: UnKnownError): string {
    return error.message ? error.message : error.toString();
  }


  logError(message: string) {
    console.log('Global Logging Error:' + message);
    // Send errors to server here
    this.zone.run(() => {
      this._snotifyService.error(message, CONSTANTS.ERR_TITLE, CONSTANTS.SnotifyToastNotificationConfig);
      this._spinner.hide();
    });
  }
}
