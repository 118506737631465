import { LoginService } from '@app-services/login.service';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from './../core/app-shared.module';
import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MainPageComponent } from './main-page/main-page.component';



@NgModule({
  declarations: [SidebarComponent, ToolbarComponent, MainPageComponent],
  imports: [
    AppSharedModule,
    RouterModule
  ],
  providers:
  [LoginService]
})
export class LayoutModule { }
