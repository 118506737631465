import { HttpErrorResponse } from '@angular/common/http';
import { ValidationError } from './validation-error';
export class UnauthorizedError  {
    private _code = 1;
    private _type = 'error';
    private _message  = 'You are unauthorized to access this page.';
    constructor(public error?: HttpErrorResponse ) {
    }
    get code(): number {
        return this._code = this.error.status;
    }
    get type(): string {
        return this._type = this.error['error'].apiReqErrors.errors[0].errorCategory;
    }

    get message(): string {
        return this._message = this.error['error'].apiReqErrors.errors[0].errorMessage;
    }
}
