import { LayoutModule } from './layout/layout.module';
import { MODULE_PATH, NAVIGATE_URI, REDIRECT_URI } from '@app-core/constants';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './layout/main-page/main-page.component';
import { AuthGurdService } from './core/auth-gurd.service';
import { AcceptInvitationComponent } from '@app-components/accept-invitation/accept-invitation.component';

const routes: Routes = [
  {
    path: MODULE_PATH.AUTH,
    loadChildren: './main/auth/auth.module#AuthModule'
  },
  {
    path: NAVIGATE_URI.ACCEPTINVITATION,
    component: AcceptInvitationComponent
  },
  {
    path: '',
    redirectTo: REDIRECT_URI.LOGIN,
    pathMatch: 'full'
  },
  {
    path: MODULE_PATH.APPS,
    component: MainPageComponent,
    children: [
      {
        path: MODULE_PATH.PROFILE,
        loadChildren: './main/user-profile/user-profile.module#UserProfileModule',
        canActivate: [AuthGurdService]
      },
      {
        path: MODULE_PATH.DASHBOARD,
        loadChildren: './main/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGurdService]
      },
      {
        path: MODULE_PATH.TENANTS,
        loadChildren: './main/tenants/tenants.module#TenantsModule',
        canActivate: [AuthGurdService]

      },
      {
        path: MODULE_PATH.USERS,
        loadChildren: './main/users/users.module#UsersModule',
        canActivate: [AuthGurdService]

      },
      {
        path: MODULE_PATH.CENTER,
        loadChildren: './main/center/center.module#CenterModule',
        canActivate: [AuthGurdService]

      },
      {
        path: MODULE_PATH.DEVICES,
        loadChildren: './main/devices/devices.module#DevicesModule',
        canActivate: [AuthGurdService]

      },
      {
        path: MODULE_PATH.STUDIES,
        loadChildren: './main/studies-list/studies-list.module#StudiesListModule',
        canActivate: [AuthGurdService]

      },
      {
        path: MODULE_PATH.DOCTORS,
        loadChildren: './main/doctors/doctors.module#DoctorsModule',
        canActivate: [AuthGurdService]
      }
    ]
  },
  {
    path: '**',
    redirectTo: REDIRECT_URI.NOT_FOUND
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), LayoutModule],
  exports: [RouterModule], providers: [AuthGurdService,
  ]
})
export class AppRoutingModule { }
