import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { REST_URI } from '@app-core/constants';
import { AppError } from '@app-models/app-error';
import { UnauthorizedError } from '@app-models/unauthorized-error';
import { ValidationError } from '@app-models/validation-error';
import { Map } from '@app-core/map';
import { throwError } from 'rxjs';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { UnKnownError } from '@app-models/unknown-error';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  baseURL: any = REST_URI.BASE_URL;

  constructor(public http: HttpClient, public store: StorageService) { }

  post(url: string, params: any): Observable<Object> {
    let body = new URLSearchParams();
    for (const key in params) {
      body.append(key, params[key]);
    }
    return this.http
      .post(this.baseURL + url, params)
      .pipe(catchError(this.handleError));
  }


  postWithFile(url: string, params: any): Observable<Object> {
    const formData = new FormData();
    formData.append('file', params.mutlipart, params.mutlipart.name);
    // let headers = new Headers();
    // headers.append('Content-Type', "multipart/form-data");
    // const headers = new HttpHeaders()
    //   .set('Content-Type', "multipart/form-data");

    // Content-type : "multipart/form-data"
    return this.http
      .post(this.baseURL + url, formData)
      .pipe(catchError(this.handleError));
  }

  put(url: string, params: any): Observable<Object> {
    let body = new URLSearchParams();
    for (let key in params) {
      body.append(key, params[key]);
    }
    return this.http
      .put(this.baseURL + url, params)
      .pipe(catchError(this.handleError));
  }

  delete(url: string): Observable<Object> {
    return this.http
      .delete(this.baseURL+url)
      .pipe(catchError(this.handleError));
  }

  get(url: string, searchParams?): Observable<Object> {
    let searchQueryString = '';
    let finalurl = '';
    if (searchParams) {
      searchQueryString = this.buildSearchQueryURI(searchParams);
      finalurl = this.baseURL + url + '?' + searchQueryString;
    } else {
      finalurl = this.baseURL + url;
    }
    return this.http.get(finalurl).pipe(
      catchError(this.handleError)
    );
  }

  // postWithHead(url: string, params: any, header: any): Observable<Object> {
  //   let body = new URLSearchParams();
  //   var headers = { 'sessionToken': header.sessionToken }
  //   for (let key in params) {
  //     body.append(key, params[key]);
  //   }
  //   return this.http.post(this.baseURL + url, params, { headers });
  // }

  // getWithHead(url: string, header: any, type: any): Observable<Object> {
  //   var headers = { 'sessionToken': header.sessionToken }
  //   let body = new URLSearchParams();
  //   return this.http.get(this.baseURL + type + url, { headers });
  // }

  // postWithFile(url: string, params: any, files: any, type: any, header: any): Observable<Object> {
  //   var headers = { 'sessionToken': header.sessionToken }
  //   let formData: FormData = new FormData();

  //   for (let i = 0; i < files.length; i++) {
  //     formData.append(`files`, files[i]);
  //   }
  //   formData.append('request', JSON.stringify(params));
  //   return this.http.post(this.baseURL + type + url, formData, { headers });
  // }

  // get(
  //   url: string,
  //   searchParams?: Map,
  //   options?: {
  //     headers?:
  //       | HttpHeaders
  //       | {
  //           [header: string]: string | string[];
  //         };
  //     observe: 'response';
  //     params?:
  //       | HttpParams
  //       | {
  //           [param: string]: string | string[];
  //         };
  //     reportProgress?: boolean;
  //     responseType: 'blob';
  //     withCredentials?: boolean;
  //   }
  // ) {
  //   let searchQueryString = '';
  //   if (searchParams) {
  //     searchQueryString = this.buildSearchQueryURI(searchParams);
  //   }
  //   const finalURL: string = this.baseURL + url + '?' + searchQueryString;
  //   return this.http.get(finalURL, options).pipe(catchError(this.handleError));
  // }

  private buildSearchQueryURI(searchParams: Map): string {
    let searchQueryURI = '';
    const regex = /[^a-zA-Z@/ 0-9._-]/g;
    searchParams.getKeys().forEach(key => {
      if (key) {
        let _value: string = searchParams.get(key);
        _value = _value.replace(/  +/g, ' ');
        _value = _value.replace(regex, '');
        if (_value === 'undefined') {
          _value = '';
        }
        searchQueryURI += key + '=' + _value + '&';
      }
    });
    return searchQueryURI;
  }

  private handleError(err: HttpErrorResponse) {
    if (err.status === 404) {
      return throwError(
        new ValidationError(JSON.parse(JSON.stringify(err.error)))
      );
    }
    if (err.status === 202) {
      return throwError(new ValidationError(JSON.parse(JSON.stringify(err.error))));
    }
    if (err.status === 403 || err.status === 409) {
      return throwError(new ValidationError(JSON.parse(JSON.stringify(err.error))));
    }
    if (err.status === 401) {
      return throwError(new UnauthorizedError(err));
    }
    return throwError(new AppError(JSON.parse(JSON.stringify(err))));
    // return throwError(
    //   new ValidationError(JSON.parse(JSON.stringify(err)))
    // );
    // return throwError(err);
  }
}
