import { environment } from '@app-environments/environment';

export const CONSTANTS = {
  SECURITY_OBJ: 'securityObj',
  SnotifyToastNotificationConfig: {
    timeout: 3000,
    maxOnScreen: 2,
    maxAtPosition: 2
  },
  DEFAULT_TOTALRECORD: 10,
  SPECIALCHAR_REGEX: /^[0-9a-zA-Z]+$/,
  EMAIL_REGEX: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
  ERR_TITLE: 'Error',
  WARNINGS_TITLE: 'Warnings',
  SUCCESS_TITLE: 'Success',
  UPDATEMSG: 'Check if the Fields are Modified before Hitting the Update Button',
  ROLENAME: 'ROLENAME'
};

export const TOOLTIP = {
  TOOLTIPPOS: 'above',
  EDIT: 'edit',
  DELETE: 'delete',
  ASSIGNPATIENT: 'Assign Patient to Doctor',
  ASSIGNDOCTOR: 'Assign Doctor to Patient',
  EXPANDED: 'Expand',
  DOWNLOAD: 'Download',
  FILE: 'Clinical Data Uploads',
  COLLAPSED: 'Collapse',
  CLEAR: 'Clear',
  DELETE_STUDY:'Delete Study',
};


/* -------------------------------------------- APP CLIENT SIDE ZONE-------------------------- */
export const MODULE_PATH = {
  AUTH: 'auth',
  DASHBOARD: 'dashboard',
  NOT_FOUND: 'notfound',
  APPS: 'apps',
  TENANTS: 'tenants',
  USERS: 'users',
  DEVICES: 'devices',
  DOCTORS: 'doctor',
  PROFILE: 'profile',
  CENTER: 'center',
  STUDIES: 'studies',
};

export const APP_URI = {
  LOGIN: 'login',
  FORGOTPASSWORD: 'forgotpassword',
  DASHBOARD: 'dashboard',
  USERPROFILE: 'profile',

  TENANTSLIST: 'tenantlist',
  TENANTSCREATE: 'tenantcrate',
  TENANTSEDIT: 'tenantedit',

  USERSLIST: 'userlist',
  USERSCREATE: 'usercreate',
  USERSEDIT: 'useredit',

  DEVICESLIST: 'devicelist',
  DEVICESCREATE: 'devicecreate',
  DEVICESEDIT: 'deviceedit',

  DOCTORSLIST: 'doctorlist',
  DOCTORSCREATE: 'doctorcreate',
  DOCTORSEDIT: 'doctoredit',

  CENTERLIST: 'centerlist',
  CENTERCREATE: 'centercreate',
  CENTEREDIT: 'centeredit',

  UPLADSTUDIES: 'studies',
  STUDIESLIST: 'studies'
};

export const URL_PARAM = {
  TENANTSID: '/:id',
  USERID: '/:id',
  DEVICEID: '/:id',
  CENTERID: '/:id',
};

export const REDIRECT_URI = {
  LOGIN: MODULE_PATH.AUTH + '/' + APP_URI.LOGIN,
  NOT_FOUND: MODULE_PATH.NOT_FOUND
};

export const NAVIGATE_URI = {
  LOGIN: '/' + MODULE_PATH.AUTH + '/' + APP_URI.LOGIN,
  FORGOTPASSWORD: '/' + MODULE_PATH.AUTH + '/' + APP_URI.FORGOTPASSWORD,
  DASHBOARD: '/' + MODULE_PATH.APPS + '/' + APP_URI.DASHBOARD,
  USERPROFILE: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.PROFILE + '/' + APP_URI.USERPROFILE,

  TENANTSLIST: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.TENANTS + '/' + APP_URI.TENANTSLIST,
  TENANTSCREATE: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.TENANTS + '/' + APP_URI.TENANTSCREATE,
  TENANTSEDIT: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.TENANTS + '/' + APP_URI.TENANTSEDIT,

  USERSLIST: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.USERS + '/' + APP_URI.USERSLIST,
  USERSCREATE: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.USERS + '/' + APP_URI.USERSCREATE,
  USERSEDIT: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.USERS + '/' + APP_URI.USERSEDIT,

  DEVICESCREATE: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.DEVICES + '/' + APP_URI.DEVICESCREATE,
  DEVICESLIST: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.DEVICES + '/' + APP_URI.DEVICESLIST,
  DEVICESEDIT: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.DEVICES + '/' + APP_URI.DEVICESEDIT,

  DOCTORSCREATE: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.DOCTORS + '/' + APP_URI.DOCTORSCREATE,
  DOCTORSLIST: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.DOCTORS + '/' + APP_URI.DOCTORSLIST,
  DOCTORSEDIT: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.DOCTORS + '/' + APP_URI.DOCTORSEDIT,

  CENTERLIST: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.CENTER + '/' + APP_URI.CENTERLIST,
  CENTERCREATE: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.CENTER + '/' + APP_URI.CENTERCREATE,
  CENTEREDIT: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.CENTER + '/' + APP_URI.CENTEREDIT,

  STUDIESLIST: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.STUDIES + '/' + APP_URI.UPLADSTUDIES,
  // STUDIESADMIN: '/' + MODULE_PATH.APPS + '/' + MODULE_PATH.STUDIES + '/' + APP_URI.STUDIESADMIN,


  ACCEPTINVITATION: "users/acceptInvitation/:userId/:orgId"

};

export const PRIVILEGE_URL = [
  { url: NAVIGATE_URI.TENANTSLIST, originalkey: '', id: 1 },
  { url: NAVIGATE_URI.TENANTSCREATE, originalkey: '', id: 2 },
  { url: NAVIGATE_URI.TENANTSEDIT, originalkey: '', id: 3 },

  { url: NAVIGATE_URI.USERSLIST, originalkey: 'PRIVILEGE_LIST_USER', id: 5 },
  { url: NAVIGATE_URI.USERSCREATE, originalkey: 'PRIVILEGE_ADD_USER', id: 6 },
  { url: NAVIGATE_URI.USERSEDIT, originalkey: 'PRIVILEGE_UPDATE_USER', id: 7 },

  { url: NAVIGATE_URI.DOCTORSLIST, originalkey: 'PRIVILEGE_LIST_DOCTOR', id: 23 },
  { url: NAVIGATE_URI.DOCTORSCREATE, originalkey: 'PRIVILEGE_ADD_DOCTOR', id: 24 },
  { url: NAVIGATE_URI.DOCTORSEDIT, originalkey: 'PRIVILEGE_UPDATE_DOCTOR', id: 25 },

  { url: NAVIGATE_URI.USERPROFILE, originalkey: 'PRIVILEGE_UPDATE_USER', id: 7 },

  { url: NAVIGATE_URI.CENTERLIST, originalkey: 'PRIVILEGE_LIST_CENTER ', id: 19 },
  { url: NAVIGATE_URI.CENTERCREATE, originalkey: 'PRIVILEGE_ADD_CENTER', id: 20 },
  { url: NAVIGATE_URI.CENTEREDIT, originalkey: 'PRIVILEGE_UPDATE_CENTER', id: 21 },

  { url: NAVIGATE_URI.DEVICESLIST, originalkey: 'PRIVILEGE_LIST_DEVICE', id: 15 },
  { url: NAVIGATE_URI.DEVICESCREATE, originalkey: 'PRIVILEGE_ADD_DEVICE', id: 16 },
  { url: NAVIGATE_URI.DEVICESEDIT, originalkey: 'PRIVILEGE_UPDATE_DEVICE', id: 17 },

  { url: NAVIGATE_URI.STUDIESLIST, originalkey: 'PRIVILEGE_LIST_STUDY', id: 10 },
  { url: NAVIGATE_URI.STUDIESLIST, originalkey: 'PRIVILEGE_ADD_STUDY', id: 11 },
  { url: NAVIGATE_URI.STUDIESLIST, originalkey: 'PRIVILEGE_UPDATE_STUDY', id: 12 },
  { url: NAVIGATE_URI.STUDIESLIST, originalkey: 'PRIVILEGE_DELETE_STUDY', id: 13 },
  { url: NAVIGATE_URI.STUDIESLIST, originalkey: 'PRIVILEGE_UPLOAD_STUDY', id: 14 },
  { url: NAVIGATE_URI.STUDIESLIST, originalkey: 'PRIVILEGE_LIST_STUDY', id: 10 },
];

export const SEARCH_URL = [
  { url: NAVIGATE_URI.TENANTSLIST, name: 'Tenant' },
  { url: NAVIGATE_URI.USERSLIST, name: 'User' },
  { url: NAVIGATE_URI.CENTERLIST, name: 'Center' },
  { url: NAVIGATE_URI.DEVICESLIST, name: 'Device' },
  { url: NAVIGATE_URI.DOCTORSLIST, name: 'Doctor' },
  { url: NAVIGATE_URI.STUDIESLIST, name: 'Studies' },
];

/* -------------------------------------------- REST API ZONE-------------------------- */

export const REST_URI_MODULE = {
  USERS: 'users',
  ORGANIZATION: 'organization',
  DEVICE: 'device',
  CENTER: 'center',
  STUDIES: 'studies',
  DASHBOARD: 'dashboard',
  ROLE: 'role'

};

export const REST_URI = {
  BASE_URL: environment.BASE_URL,
  BASE_URL_FILE: environment.BASE_URL_FILE,
  WEASIS_URL: environment.WEASIS_URL,
  LOGIN: 'login',
  FORGOTPASSWORD: 'forgotPassword',
  LOGOUT: 'logout',
  ORGGETLIST: 'getList',
  ORGCREATE: 'create',
  ORGUPDATE: 'update',
  ORGBYID: 'get',
  USERSGETLIST: 'getList',
  ALLUSERSGETLIST: 'getAll',
  USERCREATE: 'create',
  USERUPDATE: 'update',
  USERBYID: 'getUser/',
  DOCTORSLIST: 'getDoctorList/',
  ROLELIST: 'getList',
  RESETPASS: 'resetPassword',
  USERPROFILEGET: 'getUser/',
  DEVICELIST: 'getList',
  DEVICECREATE: 'create',
  DEVICEUPDATE: 'update',
  DEVICEBYID: 'getById/',
  CENTERLIST: 'getList',
  CENTERCREATE: 'create',
  CENTERUPDATE: 'update',
  CENTERBYID: 'getById',
  UPLOADSTUDIES: 'studyParameter/proccesseAI/',
  GETSTUDIESLIST: 'aets/DCM4CHEE/rs/studies',
  GETSTUDIESLISTNEW: 'studyParameter/getStudyList',
  DASHBOARD: 'getAllCount/',
  ASSIGNPATIENT: 'assignPatient',
  DELETEASSIGNPATIENT: 'deleteUserStudy/',
  UPDATESTUDYPATIENTINFO: "studyParameter/updateStudyPatientInfo",
  SAVECLINICALDETAILSCOMMENTS: "studyParameter/saveClinicalDetailsComments",
  GETCLINICALDETAILSCOMMENTS: "studyParameter/getClinicalDetailsComments",
  UPLOADATTACHMENT: "studyParameter/upload/",
  GETCLINICALDETAILSFILES: "studyParameter/getClinicalDetailsFiles",
  DELETECLINICALDETAILCOMMENT: "studyParameter/deleteClinicalDetailComment",
  DELETECLINICALDETAILFILE: "studyParameter/deleteClinicalDetailFile",
  ACCEPTINVITATION: "acceptInvitation/",
  GETCONSULTANT: "getConsultant",
  ADMINDASHBOARD: 'getSuperAdmin',
  ADMINDASHBOARDCHART: 'dashboard/getOrgDashboard',
  ADMINOVERVIEWDETAILS: 'dashboard/getUsersByType',
  ADMINSTUDIESDETAILS: 'dashboard/getStudyListByStatus',
  ADMINPROCESSINGDETAILS: 'dashboard/getStudyListByClassification',
  STUDIESDATA: 'getStudyList',
  ORGANISATIONDATA: 'getAll'
};

export const LOADING_SPINNER = {
  BDOPACITY: 0.9,
  BDCOLOR: 'rgba(51,51,51,0.3)',
  SIZE: 'medium',
  COLOR: '#fff',
  TYPE: 'ball-fussion',
  FULLSCREEN: 'true'
};

export const BLOCKED_URL = [
  // '/' + MODULE_PATH.PROFILE + '/' + APP_URI.ACCOUNTINFO,
];
