import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  private TenantListsubject = new Subject<any>();
  private UserListsubject = new Subject<any>();
  private CenterListsubject = new Subject<any>();
  private DoctorListsubject = new Subject<any>();
  private StudiesListsubject = new Subject<any>();
  private DeviceListsubject = new Subject<any>();

  private UpdateProfile  = new Subject<any>();
  constructor() { }

  sendData(message: any, type) {
    this[type + 'Listsubject' ].next(message);
  }
  getData(type): Observable<any> {
    return this[type + 'Listsubject' ].asObservable();
  }

  sendUpdateProfile() {
    this.UpdateProfile.next();
  }
  getUpdateProfile(): Observable<any> {
    return this.UpdateProfile.asObservable();
  }
}
