import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'confirm-dialog-modal',
    templateUrl: './confirm-dialog-modal.component.html',
    styleUrls: ['./confirm-dialog-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogModalComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        console.log(this.data);
    }

    close(result): void {
        this.dialogRef.close(result);
    }



}
