import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
/* ----------------------------------------- Session Storage ----------------------------------------*/
  setItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    const value = sessionStorage.getItem(key) || undefined;
    if (value) {
      return JSON.parse(value) as any;
    }
    return null;
  }

  deleteItem(key: string) {
    sessionStorage.removeItem(key);
  }

  clear() {
    sessionStorage.clear();
    // localStorage.clear();
  }
  /* ----------------------------------------- Local Storage ----------------------------------------*/
  searchSetItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  searchGetItem(key: string): any {
    const value = localStorage.getItem(key) || undefined;
    if (value) {
      return JSON.parse(value) as any;
    }
    return null;
  }

  deleteSearchItem(key: string) {
    localStorage.removeItem(key);
  }
}
