import { CONSTANTS, PRIVILEGE_URL } from '@app-core/constants';
import { Component, OnInit } from '@angular/core';
import { NAVIGATE_URI } from '@app-core/constants';
import { SecurityObject } from '@app-models/security-object';
import { StorageService } from '@app-services/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  roleName: any;
  navigationItem: any = [{
    title: 'Dashboard',
    url: NAVIGATE_URI.DASHBOARD,
    isActive: true
  },
  {
    title: 'Tenants',
    url: NAVIGATE_URI.TENANTSLIST,
    isActive: true
  },
  {
    //Commented by samir
    //title: 'Users',
    title: 'Users',
    url: NAVIGATE_URI.USERSLIST,
    isActive: true
  },
  {
    title: 'Centers',
    url: NAVIGATE_URI.CENTERLIST,
    isActive: true
  },
  {
    //Commented by samir
    // title: 'Devices',
    title: 'Devices',
    url: NAVIGATE_URI.DEVICESLIST,
    isActive: true
  },
  {
    //commented by samir
    //title: 'Doctors',
    title: 'Doctors',
    url: NAVIGATE_URI.DOCTORSLIST,
    isActive: true
  },
  {
    title: 'Study List',
    url: NAVIGATE_URI.STUDIESLIST,
    isActive: true
  }
  ];
  constructor(
    private _storageService: StorageService,
  ) {
    const session: SecurityObject = this._storageService.getItem(CONSTANTS.SECURITY_OBJ);

    this.roleName = this._storageService.getItem(CONSTANTS.ROLENAME);
    console.log('roleName1', this.roleName, this.navigationItem);

    if (this.roleName == 'SUPER_ADMIN') {
      let temp = [{
        title: 'Dashboard',
        url: NAVIGATE_URI.DASHBOARD,
        isActive: true
      },
      {
        title: 'Organizations',
        url: NAVIGATE_URI.TENANTSLIST,
        isActive: true
      },
      {
        //Commented by samir
        //title: 'Users',
        title: 'Users',
        url: NAVIGATE_URI.USERSLIST,
        isActive: true
      },
      {
        title: 'Centers',
        url: NAVIGATE_URI.CENTERLIST,
        isActive: true
      },
      {
        //Commented by samir
        // title: 'Devices',
        title: 'Devices',
        url: NAVIGATE_URI.DEVICESLIST,
        isActive: true
      },
      {
        //commented by samir
        //title: 'Doctors',
        title: 'Doctors',
        url: NAVIGATE_URI.DOCTORSLIST,
        isActive: true
      },
      {
        title: 'Study List',
        url: NAVIGATE_URI.STUDIESLIST,
        isActive: true
      }
      ];

      this.navigationItem = temp;
    }
    this.navigationItem.forEach(element => {
      if (element.title !== 'Dashboard') {
        const res = this.checkAccess(session, element.url);
        if (res) {
          element.isActive = true;
        } else {
          element.isActive = false;
        }
      }
    });
  }
  ngOnInit() {
  }

  checkAccess(user, url): any {
    let data = [];
    const id = PRIVILEGE_URL.filter(res => res.url.includes(url));
    if (id && id.length > 0) {
      data = user.PRIVILEGE_LIST_USER.filter(prlg => prlg.id === id[0]['id']);
    }
    if (data.length > 0) {
      return true;
    } else {
      return false;
    }
  }

}
