import { Router } from '@angular/router';
import { NAVIGATE_URI } from './constants';
import { ValidationError } from '@app-models/validation-error';
import { StorageService } from '@app-services/storage.service';
import { SnotifyService } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';

export class BaseService {

    constructor(
      ) {}
    processSuccessResponse(res: any, successCallBack: (res: any) => void) {
        // if (res) {
            successCallBack(res);
        // }
    }
    processErrorResponse(error: any, errorCallBack: (error: ValidationError) => void) {
        if (error instanceof ValidationError) {
            errorCallBack(error);
        } else {
            throw error;
        }
    }


}