<nav id="sidebar">
  <div class="sidebar-header">
    <img style="height: 37px;" src="../../../assets/images/logo2.png">
  </div>

  <ul class="list-unstyled components">

    <!-- <li class="active">
            <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Home</a>
            <ul class="collapse list-unstyled" id="homeSubmenu">
                <li>
                    <a href="#">Home 1</a>
                </li>
                <li>
                    <a href="#">Home 2</a>
                </li>
                <li>
                    <a href="#">Home 3</a>
                </li>
            </ul>
        </li> -->
    <ng-container *ngFor="let item of navigationItem">
      <li *ngIf="item.isActive">
        <a [routerLink]="item.url" routerLinkActive="active">{{item.title}}</a>
      </li>
    </ng-container>

    <!-- <li class="active">
      <a href="#">Dashboard</a>
    </li>

    <li>
      <a routerLink="CONSTANTS.NAVIGATE_URI.TENANTS" [routerLinkActive]="active">Tenants</a>
    </li>
    <li>
      <a href="#">Users</a>
    </li>
    <li>
      <a href="#">Devices</a>
    </li>
    <li>
      <a href="#">Studys</a>
    </li> -->
  </ul>


</nav>
